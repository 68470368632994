<div class="container-fluid p-0 d-flex">
    <app-sidebar></app-sidebar>

    <div class="container-fluid d-flex" *ngIf="isSearch">
        <div class="search mx-auto my-auto w-50">
            <i class="fa fa-search icon"></i>
            <input class="w-100" placeholder="Search report" (change)="globalSearch($event.target.value)" autofocus>
        </div>
    </div>
    <div class="container-fluid " *ngIf="!isSearch">
        <div class="container-fluid">
            <div class="top-card">
                <div class="row w-100 mx-auto d-flex p-2">
                    <p class="my-auto ml-3">Reports</p>
                    <img class="ml-auto logo-top" src="../../../assets/logo.png">
                </div>
            </div>
        </div>
        <div class="container-fluid ">
            <div class="row mx-auto my-4 w-100">
                <button type="button" class="btn btn-transparent mr-3 font-14px" (click)="goBack()">Back</button>
                <button type="button" class="btn btn-transparent border-bottom-custom font-14px">
                    Report</button>
            </div>
            <div class="search float-right padding-for-large-screens-reports-search  my-auto">
                <i class="fa fa-search icon"></i>
                <input class="w-100" placeholder="Search" [(ngModel)]="searchValue" (ngModelChange)="search()">
            </div>
        </div>

        <div class="container-fluid">
            <div class="row w-100 padding-for-large-screens-reports d-flex justify-content-center mx-auto">
                <div class="tp-wrapper" *ngFor="let cardItem of cardData;let i =index">
                    <div class="card  tp-box card-details my-3 mx-1" *ngIf="seeMore || i < 8" [@myAnimation]="seeMore"
                        [@flipState]="cardItem.flip">
                        <div class="modal-content h-auto" *ngIf="selectedItem === cardItem"
                            [ngClass]="{'tp-box__side tp-box__back': cardItem.flip === 'active' ? true : false}">
                            <div class="modal-body">
                                <div class="row d-flex w-100 mx-auto selected-header">
                                    <p class="w-75 header font-weight-700 font-20px my-auto">
                                        {{selectedItem.REPORTNAME}}</p>
                                    <span class="ml-auto">
                                        <img class="icon-class" *ngIf="selectedItem.REPORTINGTOOL === 'PDF'"
                                            src="../../../assets/pdf.png">
                                        <img class="icon-class" *ngIf="selectedItem.REPORTINGTOOL === 'Power BI'"
                                            src="../../../assets/power-bi.svg">
                                        <img class="icon-class" *ngIf="selectedItem.REPORTINGTOOL === 'Powerpoint'"
                                            src="../../../assets/power-point.png">
                                        <img class="icon-class"
                                            *ngIf="selectedItem.REPORTINGTOOL === 'excel' || selectedItem.REPORTINGTOOL ===  'Excel'"
                                            src="../../../assets/excel-icon.png">
                                        <img class="icon-class" *ngIf="selectedItem.REPORTINGTOOL === 'Tableau'"
                                            src="../../../assets/tableau.png">
                                    </span>
                                </div>
                                <hr class="mb-0">
                                <div class="row mx-auto w-100 d-block my-1">
                                    <p class="font-11px color-purple mb-1">Team</p>
                                    <p class="font-10px">{{selectedItem.ORGUNITL2}}</p>

                                </div>
                                <div class="row mx-auto w-100 d-block my-1">
                                    <p class="font-11px color-purple mb-1">Refresh Frequency</p>
                                    <p class="font-10px">{{selectedItem.FREQUENCY}}</p>

                                </div>
                                <div class="row mx-auto w-100 d-block my-1">
                                    <p class="font-11px color-purple mb-1">Description</p>
                                    <p class="font-10px">{{selectedItem.DESCRIPTION}}</p>
                                </div>
                                <div class="row mx-auto w-100 d-block my-1">
                                    <p class="font-11px color-purple mb-1">Primary Source Data</p>
                                    <p class="font-10px">{{selectedItem.PRIMARYSOURCEDATA}}</p>
                                </div>
                                <div class="row mx-auto w-100 d-block my-1">
                                    <p class="font-11px color-purple mb-1">Stakeholders</p>
                                    <p class="font-10px">{{selectedItem.KEYSTAKEHOLDERS}}</p>
                                </div>
                                <div class="row mx-auto w-100 d-block my-1">
                                    <p class="font-11px color-purple mb-1">Key Contact</p>
                                    <p class="font-10px">{{selectedItem.KEYCONTACT}}</p>
                                </div>


                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-border w-100" (click)="onClickBack()">Back</button>
                                <button type="button" class="btn btn-border w-100"
                                    (click)="goToLink(selectedItem)">Access
                                    Report</button>
                            </div>
                        </div>
                        <div *ngIf="selectedItem !== cardItem"
                            [ngClass]="{'tp-box__side tp-box__front': cardItem.flip === 'inactive'? true : false}">
                            <div class="row card-blue d-flex w-100 mx-auto p-2">
                                <!-- <span class="w-100 ">
                                    <span class="daily-button mb float-left"
                                        title='{{cardItem.Category}}/{{cardItem.SubCategory}}'>{{cardItem.Category}}/{{cardItem.SubCategory}}</span>
                                    <span class="daily-button float-right">Daily</span>
                                </span> -->
                                <p class="w-75 mt-1">{{cardItem.REPORTNAME}}</p>
                                <span class="ml-auto">
                                    <p class="daily-button mb-2">{{cardItem.FREQUENCY}}</p>
                                    <img class="icon-class" *ngIf="cardItem.REPORTINGTOOL === 'PDF'"
                                        src="../../../assets/pdf-color.png">
                                    <img class="icon-class" *ngIf="cardItem.REPORTINGTOOL === 'Power BI'"
                                        src="../../../assets/power-bi.svg">
                                    <img class="icon-class" *ngIf="cardItem.REPORTINGTOOL === 'Powerpoint'"
                                        src="../../../assets/powerpoint-color.png">
                                    <img class="icon-class"
                                        *ngIf="cardItem.REPORTINGTOOL === 'excel' || cardItem.REPORTINGTOOL ===  'Excel'"
                                        src="../../../assets/excel-icon.png">
                                </span>
                                <p class="type-box"> {{cardItem.ORGUNITL2}} </p>
                            </div>
                            <div class="row mx-auto w-100 my-auto">
                                <p class="description my-3 ">{{cardItem.DESCRIPTION}}</p>
                                <div class="col-12 d-flex">
                                    <button type="button" class="btn btn-grey ml-auto" data-toggle="modal"
                                        data-target="#myModal" (click)="selectedCard(cardItem, i)">Find out
                                        more</button>
                                    <button class="btn btn-blue ml-auto" (click)="goToLink(cardItem)">Access</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="empty-search" *ngIf="searchValue && cardData.length === 0">No records found</div>
            <div class="w-100" *ngIf="cardData && cardData.length > 8">
                <button class="float-right mb-3 see-more mx-5"
                    (click)="seeMoreOrLess()">{{seeMore?'See less ':'See more'}}</button>
            </div>
        </div>
    </div>
</div>