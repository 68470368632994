<div class="container-fluid p-0 d-flex">
    <app-sidebar></app-sidebar>
    <div class="container-fluid p-0 p-3rem">
        <div class="container-fluid my-3">
            <div class="top-card">
                <div class="row w-100 mx-auto d-flex  ">
                    <div class="col-sm-12 col-md-12 col-lg-9 py-5 pl-4">
                        <img class="logo-top" src="../../../assets/logo.png">
                        <span class="">Customer Markets 3D</span>
                    </div>
                    <div class="col-sm-12 col-md-0 col-lg-3">
                        <img class="float-right pt-3 cartoon-image" src="../../../assets/headerImg.png">
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid my-2">
            <div class="row mx-auto w-100 my-5 pl-2 d-flex justify-content-center mx-auto">
               
                <div class="card card-details cursor-pointer my-2 mx-2" *ngFor="let cardItem of cardData;let i = index"
                    (click)="goToReports(i, cardItem.ORGUNITL2)">
                    <div class="row card-blue d-block w-100 mx-auto p-2">
                       <!-- <i class="fa {{cardItem.ICON}} fa-2x text-center mx-auto d-block mb-3 mt-4" aria-hidden="true"></i> -->
					    <img class=" text-center mx-auto d-block mb-3 mt-2" style="height:70px" src="../../../assets/icons/{{cardItem.ICON}}">
                        <p class="my-auto text-center mb-2">{{cardItem.ORGUNITL2}}</p>
                    </div>
                    <div class="row mx-auto w-100">
                        <p class="p-2 font-13px description my-1">{{cardItem.L2Description}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>