<div class="container-fluid p-0 d-flex">
    <app-sidebar></app-sidebar>
    <div class="container-fluid p-0 p-3rem">
        <div class="container-fluid">
            <div class="top-card">
                <div class="row w-100 mx-auto d-flex p-3">
                    <div class="col-sm-12 col-md-9 col-lg-9">
                        <p class="my-auto ml-3">{{displayHeading}}</p>
                    </div>
                    <div class="col-sm-12 col-md-3 col-lg-3">
                        <img class="ml-auto logo-top float-right" src="../../../assets/logo.png">
                    </div>
                </div>
            </div>
        </div>
        <div class="container my-4 border-bottom scroller">
            <ul class="scrolling-wrapper">
                <li class="card-scroll" *ngFor="let item of cardData; index as i">
                    <button type="button" class="btn btn-transparent font-14px" (click)="selectedItem(i,item.ORGUNITL2)"
                        [ngClass]="{'border-bottom-custom': i == selectedTab }">{{item.ORGUNITL2}}</button>
                </li>

            </ul>
        </div>
        <div class="container-fluid my-2">
            <div class="row mx-auto w-100 padding-for-large-screens d-flex justify-content-center mx-auto">
                <div class="card card-details my-4 mx-2" *ngFor="let item of selectedCardData; index as i">
                    <div class="row card-blue d-flex w-100 mx-auto p-2">
                        <p class="w-75 my-auto">{{item.ORGUNITL3}}</p>
                        <span class="ml-auto d-flex">
                        </span>
                        <!-- <p class="type-box">
                            {{item.ORGUNITL3TYPE}}
                        </p> -->
                    </div>
                    <div class="row mx-auto my-auto w-100">
                        <p class="p-2 font-15px description my-1">{{item.L3Description}}</p>
                    </div>
                    <div class="row mx-auto w-100 my-auto my-1">
                        <div class="col-12 d-flex my-1">
                            <button class="btn btn-blue mx-auto" (click)="goToReports(i)">View Reports</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>