<nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top box-shadow">
    <div class="container">
        <a class="navbar-brand" href="#">
          NodeJS-Angular-Starter
      </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" routerLink="/">Home
          <span class="sr-only">(current)</span>
        </a>
                </li>

                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" routerLink="/category">Example</a>
                </li>
            </ul>
        </div>
    </div>
</nav>